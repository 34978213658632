import { useState, useEffect, Fragment } from "react";
import { Modal, Box } from "@mui/material";
import { useSelector } from "react-redux";
import { store } from "../../functions/store";
import { TranslatedText, translatedTextOnly } from "../../functions/translate";
import { setUserAgreedToCookies, setUserAgreedToAnalyticsCookie, setUserAgreedToPreferencesCookie } from "../../functions/store/reducers/cookies";
import { didUserAgreedToCookies, userAgreesToCookies, didUserAgreedToAnalyticsCookie, userAgreesToAnalyticsCookie, didUserAgreedToPreferencesCookie, userAgreesToPreferencesCookie } from "../../functions/cookies";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

const CookiesBanner = ({available}) => {
    const english = useSelector((state) => state.settings.english);
    const userAgreedToCookies = useSelector((state) => state.cookies.userAgreedToCookies);

    const [customizeModalOpen, setCustomizeModalOpen] = useState(false);
    const [authorizeAnalytics, setAuthorizeAnalytics] = useState(true);
    const [authorizePreferences, setAuthorizePreferences] = useState(true);
    const [bannerText, setBannerText] = useState("");
    const [bannerLink, setBannerLink] = useState("");
    const [modalText, setModalText] = useState("");
    const [modalLink, setModalLink] = useState("");

    const userAcceptsAllCookies = () => {
        setCookiesAgreements(true, true);
    };

    const userDeclinesAllCookies = () => {
        setCookiesAgreements(false, false);
    };

    const userChoosesWhichCookieToKeep = () => {
        setCookiesAgreements(authorizeAnalytics, authorizePreferences);
    };

    const setCookiesAgreements = (analytics, preferences) => {
        if (analytics){
            userAgreesToAnalyticsCookie();
        }
        if (preferences){
            userAgreesToPreferencesCookie();
        }
        store.dispatch(setUserAgreedToAnalyticsCookie(analytics));
        store.dispatch(setUserAgreedToPreferencesCookie(preferences));
        store.dispatch(setUserAgreedToCookies(true));
        userAgreesToCookies();
        setCustomizeModalOpen(false);
    };

    useEffect(()=>{
        store.dispatch(setUserAgreedToAnalyticsCookie(didUserAgreedToAnalyticsCookie()));
        store.dispatch(setUserAgreedToPreferencesCookie(didUserAgreedToPreferencesCookie()));
        store.dispatch(setUserAgreedToCookies(didUserAgreedToCookies()));
    },[]);

    useEffect(()=>{
        setBannerText(translatedTextOnly({
            en: "We use cookies to improve the quality of your experience on our services. By clicking \"Accept\", you agree to our use of cookies. Read more in our ",
            fr: "Les témoins (cookies) nous permettent d'améliorer votre expérience utilisateur sur nos divers services. En cliquant sur « Accepter », vous consentez à leur utilisation. Pour en savoir plus, consulter notre "
        }));
        setBannerLink(translatedTextOnly({
            en: "privacy Policy",
            fr: "politique de confidentialité"
        }));
        setModalText(translatedTextOnly({
            en: "We use a variety of cookies to enhance your experience on our website. You can choose which cookie you allow. Disabling cookies may affect your browsing on our site. To learn more, read our ",
            fr: "Nous utilisons plusieurs types de cookies pour améliorer votre expérience sur notre site. Vous pouvez choisir les types de cookies que vous autorisez. Cependant, désactiver des cookies peut affecter votre navigation sur le site. Pour en savoir plus, lisez notre "
        }));
        setModalLink(translatedTextOnly({
            en: "privacy policy and terms of use",
            fr: "politique de confidentialité et nos conditions d'utilisation"
        }));
    },[english]);

    return (
        <Fragment>
            <Modal
                open={customizeModalOpen}
                onClose={()=>{setCustomizeModalOpen(false);}}
                disableScrollLock={true}
                slotProps={{
                    backdrop: {
                        sx: {
                            backgroundColor: "rgba(0,0,0,0.3)"
                        }
                    }
                }}>
                    <Box sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100vh",
                        width: "fit-content",
                        margin: "auto",
                        outline: "none"
                    }}>
                        <div className="cook-customize-modal">
                            <div>
                                <div className="cook-customize-modal-header">
                                    <TranslatedText en="Cookie preferences" fr="Préférences de cookies"/>
                                    <i onClick={()=>{setCustomizeModalOpen(false);}}><FontAwesomeIcon icon={faXmark}/></i>
                                </div>
                                <p>{modalText}<a href="/" target="_blank">{modalLink}</a>.</p>
                                <div>
                                    <PreferenceElement
                                        toggle={null}
                                        activated={true}
                                        titleEn="Necessary"
                                        titleFr="Nécessaire"
                                        textEn="These cookies ensure basic functionalities of the website. They are essential for our services to function properly and cannot be disabled."
                                        textFr="Ces cookies garantissent les fonctionnalités de base du site. Ils sont essentiels au bon fonctionnement de nos services et ne peuvent pas être désactivés."/>
                                    <PreferenceElement
                                        toggle={()=>{setAuthorizeAnalytics(!authorizeAnalytics);}}
                                        activated={authorizeAnalytics}
                                        titleEn="Analytics"
                                        titleFr="Analytique"
                                        textEn="These cookies allow us to monitor user interaction and the overall performance of the site, allowing us to improve user experience."
                                        textFr="Ces cookies nous permettent de surveiller les intéractions utilisateurs ainsi que les performances générales du site. Ceci nous permet d'améliorer l'expérience utilisateur."/>
                                    <PreferenceElement
                                        toggle={()=>{setAuthorizePreferences(!authorizePreferences);}}
                                        activated={authorizePreferences}
                                        titleEn="Preferences"
                                        titleFr="Préférences"
                                        textEn="Preferences cookies are used to store user preferences, such as language and others, to provide a personalized browsing experience."
                                        textFr="Les cookies de préférences sont utilisés pour retenir les préférences utilisateurs, telles que la langue et autres, afin de fournir une expérience de navigation personnalisée."/>
                                </div>
                            </div>
                            <div></div>
                            <div className="cook-customize-modal-btns">
                                <BannerButton
                                    onclick={()=>{userAcceptsAllCookies();}}
                                    en="Accept all"
                                    fr="Accepter tout"/>
                                <BannerButton
                                    onclick={()=>{userDeclinesAllCookies();}}
                                    en="Reject all"
                                    fr="Refuser tout"/>
                                <BannerButton
                                    onclick={()=>{userChoosesWhichCookieToKeep();}}
                                    en="Save"
                                    fr="Enregistrer"/>
                            </div>
                        </div>
                    </Box>
            </Modal>
            {available ? (
                <div className={`cook-banner ${userAgreedToCookies || customizeModalOpen ? "cook-banner-closed" : ""}`}>
                <div>
                    <p>{bannerText}<a href="/" target="_blank">{bannerLink}</a>.</p>
                </div>
                <div>
                    <BannerButton onclick={()=>{userAcceptsAllCookies();}} en="Accept" fr="Accepter"/>
                    <BannerButton onclick={()=>{setCustomizeModalOpen(true);}} en="Customize" fr="Personnaliser"/>
                </div>
            </div>
            ) : <></>}
        </Fragment>
    );
};

const BannerButton = ({onclick, en, fr}) => {
    return (
        <div className="cook-banner-btn" onClick={onclick}>
            <div><TranslatedText en={en} fr={fr}/></div>
        </div>
    );
};

const PreferenceElement = ({toggle, activated, titleEn, titleFr, textEn, textFr}) => {
    return (
        <div className="cook-preference-element">
            <TranslatedText en={titleEn} fr={titleFr}/>
            <div>
                <TranslatedText en={textEn} fr={textFr}/>
                <Toggle onClick={toggle} activated={activated}/>
            </div>
        </div>
    );
};

const Toggle = ({onClick, activated}) => {
    return(
        <div
            onClick={onClick !== null ? onClick : ()=>{}}
            className={`cook-toggle ${activated ? "cook-toggle-activated" : ""} ${onClick === null ? "cook-toggle-readonly" : ""}`}>
            <div>
                <div></div>
            </div>
        </div>
    );
};



export default CookiesBanner;