import { useState, useEffect } from "react";

const Logo = () => {

    const [christmas, setChristmas] = useState(false);
    const [newYear, setNewYear] = useState(false);

    useEffect(()=>{
        setChristmas(isChristmas);
        setNewYear(isNewYear);
    },[]);

    const isChristmas = () => {
        let date = new Date();
        let month = date.getMonth();
        let day = date.getDate();
        return month === 11 && day >= 16 && day <= 26;
    };

    const isNewYear = () => {
        let date = new Date();
        let month = date.getMonth();
        let day = date.getDate();
        return (month === 11 && day === 31) || (month === 0 && day <= 2);
    };

    return (
        <div className="main-logo">
            <div>
                <div>
                </div>
                <div>
                    <div className={`${christmas ? 'christmas' : newYear ? 'new-year' : 'standard'}-logo`}>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Logo;