import { configureStore } from "@reduxjs/toolkit";

import settingsSlice from './reducers/settings.js';
import titleSlice from './reducers/title.js';
import cookiesSlice from './reducers/cookies.js';

export const store = configureStore({
    reducer: {
        settings: settingsSlice,
        title: titleSlice,
        cookies: cookiesSlice
    }
});