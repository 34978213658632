import './App.css';
import { useState, useEffect } from 'react';
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";

import Navbar from "./components/navbar/navbar";
import Footer from "./components/footer/footer";
import CookiesBanner from './components/cookiesBanner/cookiesBanner';
import { TranslatedTitle } from './functions/translate';
import { setNavigate } from './functions/gotopage';
import { usePageTracking } from "./functions/tracking";

import HomePage from './pages/home/home';
import EagleWorkPage from './pages/products/eagleWork/eagleWork';
import CompanyPage from './pages/company/company';
import NewsPage from './pages/news/news';
import ContactPage from './pages/contact/contact';
import NotFoundPage from './pages/notFound/notFound';
import UnavailablePage from './pages/unavailable/unavailable';

function App() {
  const navigate = useNavigate();

  const [available, setAvailable] = useState(false);
  const [loaded, setLoaded] = useState(false);

  usePageTracking();

  useEffect(()=>{
    setAvailable(process.env.NODE_ENV !== 'production');
    setLoaded(true);
    setNavigate(navigate);
  },[navigate]);

  return (
    <div className="App">
        <TranslatedTitle/>
        <CookiesBanner available={available}/>
        <Navbar available={available}/>
        {loaded ?
          available ?
          <Routes>
            <Route index element={<HomePage/>}/>
            <Route path="product/eagle-work" element={<EagleWorkPage/>}/>
            <Route path="company" element={<CompanyPage/>}/>
            <Route path="news" element={<NewsPage/>}/>
            <Route path="contact" element={<ContactPage/>}/>
            <Route path="*" element={<NotFoundPage/>}/>
          </Routes> :
          <Routes>
          <Route index element={<UnavailablePage/>}/>
          <Route path="*" element={<Navigate to="/"/>}/>
          </Routes> : <></>
        }
        <footer><Footer available={available}/></footer>
    </div>
  );
};

App.propTypes = {

}

export default App;
