import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    userAgreedToCookies: false,
    userAgreedToAnalyticsCookie: false,
    userAgreedToPreferencesCookie: false
};

export const cookiesSlice = createSlice({
    name: 'cookies',
    initialState: initialState,
    reducers: {
        setUserAgreedToCookies: (state, action) => {
            state.userAgreedToCookies = action.payload;
        },
        setUserAgreedToAnalyticsCookie: (state, action) => {
            state.userAgreedToAnalyticsCookie = action.payload;
        },
        setUserAgreedToPreferencesCookie: (state, action) => {
            state.userAgreedToPreferencesCookie = action.payload;
        }
    }
});

export const {
    setUserAgreedToCookies,
    setUserAgreedToAnalyticsCookie,
    setUserAgreedToPreferencesCookie
} = cookiesSlice.actions;

export default cookiesSlice.reducer;