import betterLocalStorage from "./localStorage";

export const didUserAgreedToCookies = () => {
    return betterLocalStorage.read("u53rAGr3EdtOc8k1eS0nD3v!cE", false) === true;
};

export const userAgreesToCookies = () => {
    return betterLocalStorage.save("u53rAGr3EdtOc8k1eS0nD3v!cE", true);
};

export const didUserAgreedToAnalyticsCookie = () => {
    return betterLocalStorage.read("u53rAGr3EdtOAn4!yT1C5c8k1e", false) === true;
};

export const userAgreesToAnalyticsCookie = () => {
    return betterLocalStorage.save("u53rAGr3EdtOAn4!yT1C5c8k1e", true);
};

export const didUserAgreedToPreferencesCookie = () => {
    return betterLocalStorage.read("u53rAGr3EdtOPr3FEr3NcE5c8k1e", false) === true;
};

export const userAgreesToPreferencesCookie = () => {
    return betterLocalStorage.save("u53rAGr3EdtOPr3FEr3NcE5c8k1e", true);
};

export const defaultLanguage = () => {
    return betterLocalStorage.read("3nG1!5HbYd3F4U1t");
};

export const setDefaultLanguage = (language) => {
    return betterLocalStorage.save("3nG1!5HbYd3F4U1t", language);
};