import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    english: true,
    burgerActive: false,
    productMenuClosed: true
};

export const settingsSlice = createSlice({
    name: 'settings',
    initialState: initialState,
    reducers: {
        setLanguage: (state, action) => {
            state.english = action.payload;
        },
        setBurgerActive: (state, action) => {
            state.burgerActive = action.payload;
        },
        setProductMenuClosed: (state, action) => {
            state.productMenuClosed = action.payload;
        }
    }
});

export const {
    setLanguage,
    setBurgerActive,
    setProductMenuClosed
} = settingsSlice.actions;

export default settingsSlice.reducer;