import { useEffect } from "react";

import { loadTranslatableTitles } from "../../functions/translate";

const CompanyPage = () => {

    useEffect(()=>{
        loadTranslatableTitles(
            "Entreprise",
            "Company");
    },[]);

    return (
        <div className="page"></div>
    );
};

export default CompanyPage;